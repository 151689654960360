import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AccountHolderModel } from '../models/AccountHolderModel'

interface NavProps {
  user: AccountHolderModel | undefined
}

export default function NavMenu({ user }: NavProps) {
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Navbar className="navbar-expand-sm navbar-toggleable-sm" container="fluid">
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
        <ul className="navbar-nav flex-grow">
          <NavItem>
            <NavLink tag={Link} underline='never' to="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} underline='never' to="/account">My Account</NavLink>
          </NavItem>
          <NavItem hidden={user === null}>
            <NavLink tag={Link} underline='never' onClick={(e) => { e.preventDefault(); window.location.href = '/accounts/logout' }}>Logout</NavLink>
          </NavItem>
        </ul>
      </Collapse>
    </Navbar>
  );
}
