import React, { useEffect, useState } from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'

import { CustomTabPanel, a11yProps } from './CustomTabPanel'
import { SaleGrid } from './SaleGrid'

import { AccountHolderDTO, AccountHolderModel } from '../models/AccountHolderModel'
import { SaleModel } from '../models/SaleModel'

import { ApiResponse, getRemoteData } from '../data'
import { enqueueSnackbar } from 'notistack'
import { useForm, SubmitHandler } from 'react-hook-form'
import { IMaskInput } from 'react-imask';
import { useAppContext } from '../App'
import { FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, OutlinedInput, Switch } from '@mui/material'

interface PhoneNumberProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const phoneMaskOptions = {
  mask: '(#00) 000-0000',
  definitions: { '#': /[1-9]/ }
}

const TextMaskPhone = React.forwardRef<HTMLInputElement, PhoneNumberProps>(
  function TextMaskPhone(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        {...phoneMaskOptions}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

function formatPhoneNumber(phoneNumber: string): string | null {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

type FormInputs = {
  firstName: string
  lastName: string
  email: string
  phone: string
  address1: string
  address2: string
  city: string
  stateProvince: string
  postalCode: string
}

async function loadSales(accountHolderId: string): Promise<ApiResponse> {
  const remoteUrl = `accountHolders/${accountHolderId}/sales`
  const response = await getRemoteData(remoteUrl, 'GET')

  if (response.status === 200) {
    const fetchedItems = response.body as unknown as SaleModel[]
    const items: SaleModel[] = []

    if (fetchedItems.length > 0) {
      fetchedItems.forEach((item: SaleModel) => {
        const newItem = new SaleModel()
        newItem.updateFromJson(item)
        items.push(newItem)
      })
    }

    response.object = items
  } else {
    response.object = []
  }

  return response
}

async function loadAccountHolder(accountHolderId?: string): Promise<ApiResponse> {
  if (accountHolderId !== undefined) {
    const response = await AccountHolderModel.get(accountHolderId)
    return response
  } else {
    return new ApiResponse(403, "Account Holder token not provided", null, null)
  }
}

export default function AccountHolderView() {
  const { register, handleSubmit } = useForm<FormInputs>()
  const onSubmit: SubmitHandler<FormInputs> = () => handleSave()
  const { user } = useAppContext()
  const [sales, setSales] = useState<SaleModel[]>([])
  const [accountHolder, setAccountHolder] = useState<AccountHolderDTO>(new AccountHolderDTO())
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSave = () => {
    if (accountHolder !== undefined) {
      AccountHolderModel.update(accountHolder).then(c => {
        if (c.status === 200) {
          enqueueSnackbar('Your information was saved successfully', { variant: 'success' })
        } else {
          enqueueSnackbar(c.problem?.title ?? c.message, { variant: 'error' })
        }
      }, e => { enqueueSnackbar(e ?? 'Error when saving your information', { variant: 'error' }) })
    }
  }

  useEffect(() => {
    const accountHolderId = user?.id
    if (accountHolderId) {
      loadAccountHolder(accountHolderId).then(r => {
        if (r.status === 200) {
          setAccountHolder(r.object)
        } else {
          enqueueSnackbar(r.problem?.title ?? r.message, { variant: 'error' })
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (accountHolder?.createdAt !== undefined) {
      loadSales(accountHolder.id).then(data => {
        if (data.status === 200) {
          setSales(data.object)
        } else {
          enqueueSnackbar(data.problem?.title ?? data.message, { variant: 'error' })
        }
      })
    }
  }, [accountHolder.id, accountHolder.createdAt])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' className='ve-breadcrumbs' separator={<ArrowRightIcon fontSize='small' />}>
        <Link className='ve-breadcrumb' to='/'>Home</Link>
        <div>My Account</div>
      </Breadcrumbs>

      <Tabs value={value} onChange={handleChange} aria-label="Consumer" textColor='secondary' indicatorColor='primary' className='MuiTabs-indicator'>
        <Tab label="My Profile" {...a11yProps(0)} />
        <Tab label="My Transactions" {...a11yProps(1)} disabled={accountHolder.createdAt === undefined} />
      </Tabs>

      <CustomTabPanel value={value} index={0} className='ve-grid-container'>
        <form onSubmit={handleSubmit(onSubmit)} className='ve-grid-container'>
          <div className='ve-grid-row'>
            <TextField {...register('firstName')} id='firstName' label='First Name' variant='outlined' required value={accountHolder.firstName ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, firstName: e.target.value }) }} />
            <TextField {...register('lastName')} id='lastName' label='Last Name' variant='outlined' required value={accountHolder.lastName ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, lastName: e.target.value }) }} />
          </div>
          <div className='ve-grid-row'>
            <TextField {...register('email', { required: 'Email is required', pattern: { value: /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/i, message: 'Email is not valid' } })} fullWidth id='email' type='email' label='Email' variant='outlined' required value={accountHolder.email ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, email: e.target.value }) }} />
          </div>
          <div className='ve-grid-row'>
            <FormControl variant='outlined'>
              <InputLabel variant='outlined' htmlFor='phone' required>Phone</InputLabel>
              <OutlinedInput
                {...register('phone', { minLength: 14 })}
                value={accountHolder.phoneNumber ?? ''}
                required
                onChange={(e) => { setAccountHolder({ ...accountHolder, phoneNumber: e.target.value }) }}
                name='phone'
                id='phone'
                label='Phone'
                inputComponent={TextMaskPhone as any}
              />
            </FormControl>
          </div>
          <div className='ve-grid-row ve-border-bottom'></div>
          <div className='ve-grid-row'>
            <TextField {...register('address1')} fullWidth id='address1' label='Address 1' variant='outlined' value={accountHolder.address1 ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, address1: e.target.value }) }} />
          </div>
          <div className='ve-grid-row'>
            <TextField {...register('address2')} fullWidth id='address2' label='Address 2' variant='outlined' value={accountHolder.address2 ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, address2: e.target.value }) }} />
          </div>
          <div className='ve-grid-row'>
            <TextField {...register('city')} id='address2' label='City' variant='outlined' value={accountHolder.city ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, city: e.target.value }) }} />
            <TextField {...register('stateProvince')} id='stateProvince' label='State/Province' variant='outlined' value={accountHolder.stateProvince ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, stateProvince: e.target.value }) }} />
            <TextField {...register('postalCode')} id='postalCode' label='Postal Code' variant='outlined' value={accountHolder.postalCode ?? ''} onChange={e => { setAccountHolder({ ...accountHolder, postalCode: e.target.value }) }} />
          </div>
          <div className='ve-grid-row ve-border-bottom'></div>
          <div className='ve-grid-row'>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel component='legend'>Automatically send receipts to</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={accountHolder.useEmail} onChange={e => setAccountHolder({ ...accountHolder, useEmail: e.target.checked })} name='useEmail' />}
                  label={accountHolder.email}
                />
                <FormControlLabel
                  control={<Switch checked={accountHolder.usePhone} onChange={e => setAccountHolder({ ...accountHolder, usePhone: e.target.checked })} name='usePhone' />}
                  label={formatPhoneNumber(accountHolder.phoneNumber)}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className='ve-grid-row'>
            <div className='ve-button-container'>
              <Button className='ve-button' variant='contained' type='submit' disableElevation>Save</Button>
            </div>
          </div>
        </form>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} className='ve-grid-container'>
        <SaleGrid key={accountHolder.id + '_sales'} rows={sales} />
      </CustomTabPanel>
    </>
  );
}