import React from 'react';
import Footer from './components/Footer';
import { Header } from './components/Header';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from 'material-ui-confirm'
import { AccountHolderClaim, AccountHolderModel } from './models/AccountHolderModel';
import { Role } from './auth'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fe6c2d',
      light: '#FEA781',
      dark: '#FE6C2D',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#4E4D50',
      light: '#959496',
      dark: '#2F2E30',
      contrastText: '#FFF'
    }
  }
})

type ContextType = {
  user: AccountHolderModel | undefined
}

export function useAppContext() {
  return useOutletContext<ContextType>()
}

export async function AppLoader(): Promise<AccountHolderModel | null> {
  const req = new Request('/bff/user', {
    headers: new Headers({
      'X-CSRF': '1'
    })
  })

  try {
    const response = await fetch(req)
    if (response.ok) {
      const accountHolderClaims = await response.json()
      const accountHolder: AccountHolderModel = createAccountHolder(accountHolderClaims)
      return accountHolder
    } else {
      window.location.href = '/accounts/login'
    }
  } catch (e) {
    console.error('Error checking account holder status')
  }

  return null
}

function createAccountHolder(accountHolderClaims: AccountHolderClaim[]): AccountHolderModel {
  const newAccountHolder = new AccountHolderModel()
  newAccountHolder.claims = accountHolderClaims

  const id: string | undefined = newAccountHolder.claims.find((claim) => claim.type === 'sub')?.value
  if (id !== undefined) {
    newAccountHolder.id = id
  }

  const roles: string[] | undefined = newAccountHolder.claims.find((claim) => claim.type === 'role')?.value.split(',')
  if (roles !== undefined) {
    roles.forEach((role) => {
      const r = role as Role
      newAccountHolder.roles.push(r)
    })
  }

  return newAccountHolder
}

export function Login(): void {
  window.location.href = '/accounts/login'
}

export function App(): JSX.Element {
  const accountHolder: AccountHolderModel | undefined = useLoaderData() as AccountHolderModel ?? undefined

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ConfirmProvider defaultOptions={{ confirmationButtonProps: { autoFocus: true } }} >
          <SnackbarProvider>
            <Header user={accountHolder} />
            <div className='ve-content-container'>
              <Outlet context={{ user: accountHolder } satisfies ContextType} />
            </div>
            <Footer />
          </SnackbarProvider>
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
