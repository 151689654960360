import { v4 as uuidv4 } from 'uuid'
import { getRemoteData } from '../data'

const url: string = 'accounts/'

export class AccountDTO {
  id: string = ''
  token: string = ''
  active: boolean = false
  accountHolderId?: string
  clientId?: string
  organizationId?: string
  locationId?: string
  currentBalance?: number
  cadBalance?: number
  vendBalance?: number
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export class AccountModel {
  id: string = ''
  token: string = ''
  active: boolean = false
  accountHolderId?: string
  clientId?: string
  organizationId?: string
  locationId?: string
  currentBalance?: number
  cadBalance?: number
  vendBalance?: number
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date

  static async get (id: string): Promise<AccountModel> {
    const account = new AccountModel()
    const response = await getRemoteData(url + id, 'GET')

    if (response !== null) {
      account.updateFromJson(response as unknown as AccountModel)
    }

    return account
  }

  static async update (dto: AccountDTO): Promise<AccountModel> {
    const account = new AccountModel()
    account.updateFromDto(dto)

    if (account.createdAt !== undefined) {
      const json = await getRemoteData(url + account.id, 'PUT', account.asJson)
      account.updateFromJson(json as unknown as AccountModel)
    } else {
      const json = await getRemoteData(url, 'POST', account.asJson)
      account.updateFromJson(json as unknown as AccountModel)
    }

    return account
  }

  static async delete (id: string): Promise<AccountModel> {
    const account = new AccountModel()

    getRemoteData(url + id, 'DELETE')
      .then((json) => { account.updateFromJson(json as unknown as AccountModel) }, (e) => { console.error(e) })

    return account
  }

  static create (): AccountModel {
    const account = new AccountModel()
    account.id = uuidv4()

    return account
  }

  get asJson (): string {
    return JSON.stringify({
      id: this.id,
      token: this.token,
      accountHolderId: this.accountHolderId,
      clientId: this.clientId,
      organizationId: this.organizationId,
      locationId: this.locationId,
      active: this.active,
      currentBalance: this.currentBalance,
      cadBalance: this.cadBalance,
      vendBalance: this.vendBalance,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt
    })
  }

  updateFromJson (json: AccountModel): void {
    this.id = json.id
    this.token = json.token
    this.accountHolderId = json.accountHolderId
    this.clientId = json.clientId
    this.organizationId = json.organizationId
    this.locationId = json.locationId
    this.active = json.active
    this.currentBalance = json.currentBalance
    this.cadBalance = json.cadBalance
    this.vendBalance = json.vendBalance
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
    this.deletedAt = json.deletedAt
  }

  updateFromDto (dto: AccountDTO): void {
    this.id = dto.id
    this.token = dto.token
    this.accountHolderId = dto.accountHolderId
    this.clientId = dto.clientId
    this.organizationId = dto.organizationId
    this.locationId = dto.locationId
    this.active = dto.active
    this.currentBalance = dto.currentBalance
    this.cadBalance = dto.cadBalance
    this.vendBalance = dto.vendBalance
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.deletedAt = dto.deletedAt
  }

  get asDTO (): AccountDTO {
    const dto = new AccountDTO()

    dto.id = this.id
    dto.token = this.token
    dto.accountHolderId = this.accountHolderId
    dto.clientId = this.clientId
    dto.organizationId = this.organizationId
    dto.locationId = this.locationId
    dto.active = this.active
    dto.currentBalance = this.currentBalance
    dto.cadBalance = this.cadBalance
    dto.vendBalance = this.vendBalance
    dto.createdAt = this.createdAt
    dto.updatedAt = this.updatedAt
    dto.deletedAt = this.deletedAt

    return dto
  }
}
