import { v4 as uuidv4 } from 'uuid'
import { getRemoteData } from '../data'

const url: string = 'sales/'

export class SaleDTO {
  id?: string
  saleType?: string
  transactionDate?: Date
  fundingFormulaId?: string
  userId?: string
  organizationId?: string
  accountId?: string
  accountToken?: string
  equipmentId?: string
  totalAmount?: number
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export class SaleModel {
  id?: string
  saleType?: string
  transactionDate?: Date
  fundingFormulaId?: string
  userId?: string
  organizationId?: string
  accountId?: string
  accountToken?: string
  equipmentId?: string
  totalAmount?: number
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date

  static async get (id: string): Promise<SaleModel> {
    const sale = new SaleModel()
    const response = await getRemoteData(url + id, 'GET')

    if (response !== null) {
      sale.updateFromJson(response as unknown as SaleModel)
    }

    return sale
  }

  static async update (dto: SaleDTO): Promise<SaleModel> {
    const sale = new SaleModel()
    sale.updateFromDto(dto)

    if (sale.createdAt !== undefined) {
      const json = await getRemoteData(url + sale.id, 'PUT', sale.asJson)
      sale.updateFromJson(json as unknown as SaleModel)
    } else {
      const json = await getRemoteData(url, 'POST', sale.asJson)
      sale.updateFromJson(json as unknown as SaleModel)
    }

    return sale
  }

  static async delete (id: string): Promise<SaleModel> {
    const sale = new SaleModel()

    getRemoteData(url + id, 'DELETE')
      .then((json) => { sale.updateFromJson(json as unknown as SaleModel) }, (e) => { console.error(e) })

    return sale
  }

  static create (): SaleModel {
    const sale = new SaleModel()
    sale.id = uuidv4()

    return sale
  }

  get asJson (): string {
    return JSON.stringify({
      id: this.id,
      saleType: this.saleType,
      transactionDate: this.transactionDate,
      fundingFormulaId: this.fundingFormulaId,
      userId: this.userId,
      organizationId: this.organizationId,
      accountId: this.accountId,
      accountToken: this.accountToken,
      equipmentId: this.equipmentId,
      totalAmount: this.totalAmount,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt
    })
  }

  updateFromJson (json: SaleModel): void {
    this.id = json.id
    this.saleType = json.saleType
    this.transactionDate = json.transactionDate
    this.fundingFormulaId = json.fundingFormulaId
    this.userId = json.userId
    this.organizationId = json.organizationId
    this.accountId = json.accountId
    this.accountToken = json.accountToken
    this.equipmentId = json.equipmentId
    this.totalAmount = json.totalAmount
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
    this.deletedAt = json.deletedAt
  }

  updateFromDto (dto: SaleDTO): void {
    this.id = dto.id
    this.saleType = dto.saleType
    this.transactionDate = dto.transactionDate
    this.fundingFormulaId = dto.fundingFormulaId
    this.userId = dto.userId
    this.organizationId = dto.organizationId
    this.accountId = dto.accountId
    this.accountToken = dto.accountToken
    this.equipmentId = dto.equipmentId
    this.totalAmount = dto.totalAmount
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.deletedAt = dto.deletedAt
  }

  get asDTO (): SaleDTO {
    const dto = new SaleDTO()

    dto.id = this.id
    dto.saleType = this.saleType
    dto.transactionDate = this.transactionDate
    dto.fundingFormulaId = this.fundingFormulaId
    dto.userId = this.userId
    dto.organizationId = this.organizationId
    dto.accountId = this.accountId
    dto.accountToken = this.accountToken
    dto.equipmentId = this.equipmentId
    dto.totalAmount = this.totalAmount
    dto.createdAt = this.createdAt
    dto.updatedAt = this.updatedAt
    dto.deletedAt = this.deletedAt

    return dto
  }
}
