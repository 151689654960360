import * as React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return(
    <footer>
      <span>&copy; VE Solutions, Inc.</span>
      <Link className='ve-breadcrumb' to='#'>Terms and Conditions</Link>
      <Link className='ve-breadcrumb' to='#'>Privacy Policy</Link>
      <Link className='ve-breadcrumb' to='#'>Contact Us</Link>
    </footer>
  )
}