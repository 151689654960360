import { AccountModel } from './AccountModel'
import { v4 as uuidv4 } from 'uuid'
import { ApiResponse, getRemoteData } from '../data'

const url: string = 'accountholders/'

export class AccountHolderClaim {
  type: string = ''
  value: string = ''
}

export class AccountHolderDTO {
  id: string = ''
  firstName: string = ''
  lastName: string = ''
  email: string = ''
  phoneNumber: string = ''
  address1: string = ''
  address2: string = ''
  city: string = ''
  stateProvince: string = ''
  country: string = ''
  postalCode: string = ''
  active: boolean = true
  useEmail: boolean = false
  usePhone: boolean = false
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export class AccountHolderModel {
  id: string = ''
  firstName: string = ''
  lastName: string = ''
  email: string = ''
  phoneNumber: string = ''
  address1: string = ''
  address2: string = ''
  city: string = ''
  stateProvince: string = ''
  country: string = ''
  postalCode: string = ''
  active: boolean = true
  useEmail: boolean = false
  usePhone: boolean = false
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  accounts?: AccountModel[]
  roles: string[] = []
  claims: AccountHolderClaim[] = []

  get fullName(): string {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`
  }

  static async get(id: string): Promise<ApiResponse> {
    const accountHolder = new AccountHolderModel()
    const response: ApiResponse = await getRemoteData(url + id, 'GET')

    if (response.status === 200) {
      accountHolder.updateFromJson(response.body as unknown as AccountHolderModel)
      response.object = accountHolder
      return response
    }

    return response
  }

  static async update(dto: AccountHolderDTO): Promise<ApiResponse> {
    const accountHolder = new AccountHolderModel()
    accountHolder.updateFromDto(dto)

    if (accountHolder.createdAt !== undefined) {
      const response: ApiResponse = await getRemoteData(url + accountHolder.id, 'PUT', accountHolder.asJson)
      if (response.status === 200) {
        accountHolder.updateFromJson(response.body as unknown as AccountHolderModel)
        response.object = accountHolder
      }
      return response
    } else {
      const response: ApiResponse = await getRemoteData(url, 'POST', accountHolder.asJson)
      if (response.status === 201) {
        accountHolder.updateFromJson(response.body as unknown as AccountHolderModel)
        response.object = accountHolder
      }
      return response
    }
  }

  static async delete(id: string): Promise<ApiResponse> {
    const accountHolder = new AccountHolderModel()
    const response = await getRemoteData(url + id, 'DELETE')

    if (response.status === 200) {
      accountHolder.updateFromJson(response.body as unknown as AccountHolderModel)
      response.object = accountHolder
    }

    return response
  }

  static async resetPassword(id: string): Promise<ApiResponse> {
    const response = await getRemoteData(url + `createReset?searchString=${id}`, 'GET')
    return response
  }

  static create(): AccountHolderModel {
    const accountHolder = new AccountHolderModel()
    accountHolder.id = uuidv4()

    return accountHolder
  }

  static createAccount(accountHolderId: string): AccountModel {
    const account = new AccountModel()
    account.id = uuidv4()
    account.accountHolderId = accountHolderId

    return account
  }

  get asJson(): string {
    return JSON.stringify({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      stateProvince: this.stateProvince,
      country: this.country,
      postalCode: this.postalCode,
      useEmail: this.useEmail,
      usePhone: this.usePhone,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt
    })
  }

  updateFromJson(json: AccountHolderModel): void {
    this.id = json.id
    this.firstName = json.firstName
    this.lastName = json.lastName
    this.email = json.email
    this.phoneNumber = json.phoneNumber
    this.address1 = json.address1
    this.address2 = json.address2
    this.city = json.city
    this.stateProvince = json.stateProvince
    this.country = json.country
    this.postalCode = json.postalCode
    this.useEmail = json.useEmail
    this.usePhone = json.usePhone
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
    this.deletedAt = json.deletedAt
  }

  updateFromDto(dto: AccountHolderDTO): void {
    this.id = dto.id
    this.firstName = dto.firstName
    this.lastName = dto.lastName
    this.email = dto.email
    this.phoneNumber = dto.phoneNumber
    this.address1 = dto.address1
    this.address2 = dto.address2
    this.city = dto.city
    this.stateProvince = dto.stateProvince
    this.country = dto.country
    this.postalCode = dto.postalCode
    this.useEmail = dto.useEmail
    this.usePhone = dto.usePhone
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.deletedAt = dto.deletedAt
  }

  get asDTO(): AccountHolderDTO {
    const dto = new AccountHolderDTO()

    dto.id = this.id
    dto.firstName = this.firstName
    dto.lastName = this.lastName
    dto.email = this.email
    dto.phoneNumber = this.phoneNumber
    dto.address1 = this.address1
    dto.address2 = this.address2
    dto.city = this.city
    dto.stateProvince = this.stateProvince
    dto.country = this.country
    dto.postalCode = this.postalCode
    dto.useEmail = this.useEmail
    dto.usePhone = this.usePhone
    dto.createdAt = this.createdAt
    dto.updatedAt = this.updatedAt
    dto.deletedAt = this.deletedAt

    return dto
  }
}
