import React, { useEffect, useState } from 'react'
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'

import { enqueueSnackbar } from 'notistack'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ResetRequestDTO, ResetRequestModel } from '../models/ResetTokenModel';
import { ApiResponse } from '../data';
import { Alert, InputAdornment } from '@mui/material';


type FormInputs = {
  pin: string,
  token: string
}

export async function ResetViewLoader({ params }: LoaderFunctionArgs): Promise<ApiResponse> {
  const token = params.token

  if (token !== undefined) {
    const resetRequest = new ResetRequestDTO()
    resetRequest.resetToken = token
    const response = await ResetRequestModel.validate(resetRequest)
    return response
  } else {
    return new ApiResponse(403, "Reset token not provided", null, null)
  }
}

export default function ResetView() {
  const [apiResponse, setApiResponse] = useState<ApiResponse>(useLoaderData() as ApiResponse)
  const [resetRequest, setResetRequest] = useState<ResetRequestDTO>(new ResetRequestDTO())
  const { register, handleSubmit } = useForm<FormInputs>()
  const onSubmit: SubmitHandler<FormInputs> = () => handleSave()

  const handleSave = () => {
    if (resetRequest.resetToken !== null && resetRequest.PIN !== null) {
      ResetRequestModel.redeem(resetRequest).then(c => {
        setApiResponse(c)
        if (c.status === 200) {
          enqueueSnackbar('PIN reset successfully', { variant: 'success' })
        } else {
          enqueueSnackbar(c.problem?.title ?? c.message, { variant: 'error' })
        }
      }, e => { enqueueSnackbar(e ?? 'Error when resetting PIN', { variant: 'error' }) })
    }
  }

  const handleValidation = () => {
    if (resetRequest.resetToken !== null && resetRequest.resetToken !== '') {
      ResetRequestModel.validate(resetRequest).then(c => {
        setApiResponse(c)
        if (c.status !== 200) {
          enqueueSnackbar(c.problem?.title ?? c.message, { variant: 'error' })
        }
      }, e => { enqueueSnackbar(e ?? 'Error when validating token', { variant: 'error' }) })
    }
  }

  useEffect(() => {
    if (apiResponse.object) {
      setResetRequest(apiResponse.object)
    }
  }, [apiResponse])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' className='ve-breadcrumbs' separator={<ArrowRightIcon fontSize='small' />}>
        <Link className='ve-breadcrumb' to='/'>Home</Link>
        <Link className='ve-breadcrumb' to='/resetredeem'>Reset PIN</Link>
      </Breadcrumbs>

      <form onSubmit={handleSubmit(onSubmit)} className='ve-grid-container'>
        <div className='ve-grid-row'>
          <TextField {...register('token')} id='token' label='Reset Token' variant='outlined' fullWidth required value={resetRequest.resetToken ?? ''} onChange={e => { setResetRequest({ ...resetRequest, resetToken: e.target.value }) }} onBlur={handleValidation}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                {
                  apiResponse.status === 200
                    ?
                    <CheckCircleIcon color='success' />
                    :
                    <ErrorIcon color='error' />
                }
              </InputAdornment>
            }}
          />
        </div>
        <div className='ve-grid-row'>
          <TextField {...register('pin', { pattern: /^\d{6}$/gm })} id='pin' label='Enter new PIN' variant='outlined' helperText='6 digits, numbers only (0-9)' required value={resetRequest.PIN ?? ''} disabled={resetRequest.resetToken === '' || resetRequest.redeemed || resetRequest.expired} onChange={e => { setResetRequest({ ...resetRequest, PIN: e.target.value }) }} />
        </div>
        <div className='ve-grid-row'>
          <Alert severity={apiResponse.status === 200 ? 'success' : 'error'} icon={apiResponse.status === 200 ? <CheckCircleIcon fontSize='inherit' /> : <ErrorIcon fontSize='inherit' />}>{apiResponse.status === 200 ? resetRequest.redeemed ? 'Your PIN was reset' : 'Your token has been validated' : apiResponse.problem?.title ?? apiResponse.message}</Alert>
        </div>
        <div className='ve-grid-row'>
          <div className='ve-button-container'>
            <Button className='ve-button' variant='contained' type='submit' disabled={resetRequest.resetToken === '' || resetRequest.redeemed || resetRequest.expired} disableElevation>Save</Button>
          </div>
        </div>
      </form>
    </>
  );
}