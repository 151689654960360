import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom'
import { AccountDTO, AccountModel } from '../models/AccountModel';
import { useAppContext } from '../App';
import { ApiResponse, getRemoteData } from '../data';
import { enqueueSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';

async function loadAccounts(accountHolderId: string): Promise<ApiResponse> {
  const remoteUrl = `accountHolders/${accountHolderId}/accounts`
  const response = await getRemoteData(remoteUrl, 'GET')

  if (response.status === 200) {
    const fetchedItems = response.body as unknown as AccountModel[]
    const items: AccountDTO[] = []

    if (fetchedItems.length > 0) {
      fetchedItems.forEach((item: AccountModel) => {
        const newItem = new AccountModel()
        newItem.updateFromJson(item)
        items.push(newItem.asDTO)
      })
    }

    response.object = items
  } else {
    response.object = []
  }

  return response
}

export default function HomeView() {
  const { user } = useAppContext()
  const [loading, setLoading] = useState(true)
  const [accounts, setAccounts] = useState<AccountDTO[]>([]);

  useEffect(() => {
    if (user !== undefined) {
      loadAccounts(user.id).then(data => {
        if (data.status === 200) {
          setAccounts(data.object)
          setLoading(false)
        } else {
          enqueueSnackbar(data.problem?.title ?? data.message, { variant: 'error' })
        }
      })
    }
  }, [user])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' className='ve-breadcrumbs' separator={<ArrowRightIcon fontSize='small' />}>
        <Link className='ve-breadcrumb' to='/'>Home</Link>
      </Breadcrumbs>
      <h1>VE Solutions Stored Value Card</h1>
      <CircularProgress hidden={!loading} />
      <p>My current balance is ${accounts.map(a => a?.currentBalance ?? 0).reduce((a, b) => (a + b), 0)}</p>
    </>
  )
}
