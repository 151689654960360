import * as jose from 'jose'

export const api = 'https://sv-api.veii.dev/api/'
//export const api = 'https://localhost:7260/api/'
const remoteApi = '/remote/api/'

export class Problem {
  title?: string
  status?: number
  detail?: string
}

export class ApiResponse {
  status: number
  message: string
  body: JSON | null
  problem: Problem | null
  object?: any

  constructor(status: number, message: string, body: JSON | null, problem: Problem | null) {
    this.status = status
    this.message = message
    this.body = body
    this.problem = problem
  }
}

async function postData(url: string, data: any): Promise<Response> {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: 'none'
  }

  const token: string = await AuthenticationManager.getAccessToken()
  if (token !== null) {
    headers = { ...headers, Authorization: 'Bearer ' + token }
  }

  console.debug(token)

  const result = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  })

  return result
}

const tokenKey = 'VEIIStoredValueWebsite'

class AuthenticationManager {
  token?: string
  user?: jose.JWTPayload
  userChanged: boolean = false

  updateToken(newToken: string | null): void {
    if (newToken != null) {
      window.localStorage.setItem(tokenKey, JSON.stringify(newToken))
      this.token = newToken
    } else {
      window.localStorage.removeItem(tokenKey)
      this.token = undefined
    }
  }

  constructor() {
    const storedToken = window.localStorage.getItem(tokenKey)

    if (storedToken != null) {
      this.token = JSON.parse(storedToken)
      try {
        if (this.token != null) {
          this.user = jose.decodeJwt(this.token)
        }
      } catch (error) {
        console.log(error)
      }
    }

    window.addEventListener('storage', (event) => {
      if (event.key === tokenKey) {
        let newToken = event.newValue
        if (newToken !== null) {
          newToken = JSON.parse(newToken)
          this.updateToken(newToken)
        }
      }
    }, false)

    setInterval(
      () => { this.checkTokenExpiration() }, 30000
    )
  }

  async login(email: string, password: string): Promise<any | null> {
    const result = await postData(remoteApi + 'login', { email, password })
    if (result.status === 200) {
      return result.json
    } else {
      return null
    }
  }

  async refresh(token: string): Promise<any | null> {
    const result = await postData(remoteApi + 'refresh', { token })
    if (result.status === 200) {
      return result.json
    } else {
      return null
    }
  }

  checkTokenExpiration(): void {
    if (this.user?.exp !== undefined) {
      const expired = this.user.exp < (Date.now() - 300000) / 1000
      if (expired) {
        const storedToken = window.localStorage.getItem(tokenKey)
        if (storedToken != null) {
          this.refresh(storedToken)
            .then((newToken) => {
              this.updateToken(newToken)
            })
            .catch(() => {
              this.updateToken(null)
            })
        }
      }
    }
  }

  logout(): void {
    this.updateToken(null)
  }

  static getAccessToken(): string | PromiseLike<string> {
    const result = window.localStorage.getItem(tokenKey)
    if (result != null) {
      return result
    } else {
      return ''
    }
  }
}

export async function getRemoteData(url: string, method: string, data?: any): Promise<ApiResponse> {
  let body = null

  if (data !== undefined) {
    body = data
  }

  const request = new Request(remoteApi + url, {
    method,
    body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-CSRF': '1'
    })
  })

  var json: JSON | null = null
  var problem: Problem | null = null
  const response = await fetch(request)

  if (response.status === 200 || response.status === 201) {
    json = await response.json()
  } else {
    try {
      problem = await response.json() as Problem
    } catch (e) {
      console.debug(e)
    }
  }

  return new ApiResponse(response.status, response.statusText, json, problem)
}
