// import { v4 as uuidv4 } from 'uuid'
import { getRemoteData, ApiResponse } from '../data'

const url: string = 'accountHolders/'

export class ResetToken {
  requestId?: string
  createdAt?: Date
}

export class ResetRequestDTO {
  PIN: string = ''
  resetToken: string = ''
  decodedToken?: ResetToken
  redeemed: boolean = false
  expired: boolean = false
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export class ResetRequestModel {
  PIN: string = ''
  resetToken: string = ''
  decodedToken?: ResetToken
  redeemed: boolean = false
  expired: boolean = false
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date

  static async validate (dto: ResetRequestDTO): Promise<ApiResponse> {
    const resetRequest = new ResetRequestModel()
    resetRequest.updateFromDto(dto)

    const response: ApiResponse = await getRemoteData(url + 'validateReset', 'POST', resetRequest.asJson)
    
    if (response.status === 200) {
      response.object = response.body as unknown as ResetRequestDTO
      return response
    } else {
      return response
    }
  }

  static async redeem (dto: ResetRequestDTO): Promise<ApiResponse> {
    const resetRequest = new ResetRequestModel()
    resetRequest.updateFromDto(dto)

    const response: ApiResponse = await getRemoteData(url + 'redeemReset', 'POST', resetRequest.asJson)

    if (response.status === 200) {
      response.object = response.body as unknown as ResetRequestDTO
      return response
    } else {
      return response
    }
  }

  get asJson (): string {
    return JSON.stringify({
      PIN: this.PIN,
      resetToken: this.resetToken,
      redeemed: this.redeemed,
      expired: this.expired,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt
    })
  }

  updateFromJson (json: ResetRequestModel): void {
    this.PIN = json.PIN
    this.resetToken = json.resetToken
    this.redeemed = json.redeemed
    this.expired = json.expired
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
    this.deletedAt = json.deletedAt
  }

  updateFromDto (dto: ResetRequestDTO): void {
    this.PIN = dto.PIN
    this.resetToken = dto.resetToken
    this.redeemed = dto.redeemed
    this.expired = dto.expired
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.deletedAt = dto.deletedAt
  }

  get asDTO (): ResetRequestDTO {
    const dto = new ResetRequestDTO()

    dto.PIN = this.PIN
    dto.resetToken = this.resetToken
    dto.redeemed = this.redeemed
    dto.expired = this.expired
    dto.createdAt = this.createdAt
    dto.updatedAt = this.updatedAt
    dto.deletedAt = this.deletedAt

    return dto
  }
}
