import React from 'react'
import { DataGrid, useGridApiRef, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { SaleDTO, type SaleModel } from '../models/SaleModel'

function getFormattedAmount (params: GridRenderCellParams<SaleModel>): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(params.row.totalAmount ?? 0)
}

function getDate (value: string): Date | null {
  if (Date.parse(value?.toString())) {
    const date: Date = new Date(value)     
    return date
  } else {
    return null
  }
}

function getRowId(row: SaleDTO) {
  return `${row.id}.${row.fundingFormulaId}`
}

const columns: GridColDef[] = [
  { field: 'saleType', headerName: 'Type', flex: 1 },
  { field: 'totalAmount', headerName: 'Total Amount', renderCell: (params: GridRenderCellParams<SaleModel>) => { return getFormattedAmount(params) }, flex: 1 },
  { field: 'transactionDate', headerName: 'Transaction Date/Time', type: 'dateTime', valueGetter: ({ value }) => getDate(value), flex: 1 }
]

interface SaleGridProps {
  rows: SaleModel[]
}

export function SaleGrid ({ rows }: SaleGridProps): JSX.Element {
  const apiRef = useGridApiRef()
  return (
    <>
      <div className='ve-grid-row'>
        <div className='ve-button-container'>
          <Button className='ve-button' variant='outlined' disableElevation onClick={() => apiRef.current.exportDataAsCsv()}>Download</Button>
        </div>
      </div>
      <DataGrid pageSizeOptions={[100]}  rows={rows} columns={columns} apiRef={apiRef} getRowId={getRowId}/>
    </>

  )
}
