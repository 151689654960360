import React from 'react';

import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import { AccountHolderModel } from '../models/AccountHolderModel'

interface HeaderProps {
  user: AccountHolderModel | undefined
}

export function Header({ user }: HeaderProps) {
  return (
    <header>
      <Link to="/"><img src='VESolutions_Logo.png' alt='VE Solutions' className='ve-logo' /></Link>
      <NavMenu user={user} />
      {/* <SearchBar /> */}
    </header>
  );

}
