import HomeView from "./components/Home";
import { App, AppLoader } from "./App";
import ErrorPage from "./error-page";
import ResetView, { ResetViewLoader } from "./components/ResetView";
import AccountHolderView from "./components/AccountHolderView";

const AppRoutes = [
  {
    path: "/",
    element: <App />,
    loader: AppLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomeView />
      },
      {
        path: 'account/',
        element: <AccountHolderView />
      },
      {
        path: 'resetRedeem/:token',
        element: <ResetView />,
        loader: ResetViewLoader
      },
      {
        path: 'resetRedeem/',
        element: <ResetView />,
        loader: ResetViewLoader
      },
      {
        path: 'terms',
        element: <HomeView />
      },
      {
        path: 'privacy',
        element: <HomeView />
      },
      {
        path: 'contact',
        element: <HomeView />
      }
    ]
  }
];

export default AppRoutes;
