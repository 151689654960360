import React from 'react'
import { useRouteError } from 'react-router-dom'
import './custom.css'

export default function ErrorPage (): JSX.Element {
  const error: any = useRouteError()
  console.error(error)

  return (
        <div id="error-page">
            <img src='VESolutions_Logo.png' alt='VE Solutions' className='ve-logo'/>
            <h1>Oops!</h1>
            <p>An unexpected error has occurred.</p>
            <p>
                <i>{(error.message !== undefined) ? error.message : '' }</i>
            </p>
        </div>
  )
}
